@import url("https://fonts.googleapis.com/css?family=Marvel");

.digital-twins-page {
  min-height: 100vh;
  background: #0f0f0f;
  padding: 100px 50px 0 50px;
}

.digital-twins-title {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
  font-family: Marvel;
  color: var(--general);
  text-transform: uppercase;
}

.digital-twins-secondary-text-container {
  display: flex;
  padding-top: 20px;
  justify-content: center;
}

.digital-twins-secondary-text {
  width: 860px;
  height: 114px;
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  color: var(--general);
}

.digital-twins-technologies-container {
  padding-top: 150px;
}

.digital-twins-technologies {
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  color: var(--secondary);
}

.digital-twins-page-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 2fr));
}

.digital-twins-content-star-even {
  display: flex;
  padding-top: 90px;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.digital-twins-content-star-odd {
  display: flex;
  padding-top: 177px;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.technologies-name-section-odd {
  padding-top: 37.5px;
}

.technologies-name-section-even {
  padding-top: 43.5px;
}

.technologie-name {
  font-size: 34px;
  font-weight: 700;
  line-height: 43px;
  color: var(--general);
}

.digital-twins-page-content:last-child {
  padding-bottom: 37.5px;
}

.digital-twins-content-stars-0 img {
  animation-delay: 0s !important;
}

.digital-twins-content-stars-1 img {
  animation-delay: 1s !important;
}

.digital-twins-content-stars-2 img {
  animation-delay: 2s !important;
}

.digital-twins-content-stars img {
  animation: scale 1s linear 1;
  animation-timing-function: ease-in-out;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 1580px) {
  .digital-twins-content-star-even {
    padding-top: 90px;
  }
}

@media (max-width: 700px) {
  .digital-twins-page-content {
    grid-template-columns: repeat(auto-fit, minmax(1rem, 2fr));
  }

  .digital-twins-content-hidden {
    display: none;
  }

  .technologies-name-section-even {
    padding-top: 37.5px;
  }
}

@media (max-width: 800px) {
  .digital-twins-page {
    min-height: 55vh;
  }
}

@media (max-width: 500px) {
  .digital-twins-technologies-container {
    padding-top: 250px;
  }

  .digital-twins-content-star-odd,
  .digital-twins-content-star-even {
    padding-top: 40px;
  }

  .technologies-name-section-odd,
  .technologies-name-section-even {
    padding-top: 10px;
  }
}
