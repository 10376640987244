.footer-page-container {
  width: 100%;
  display: flex;
  min-height: 60vh;
  position: relative;
  background: #0f0f0f;
  justify-content: center;
}

.footer-page {
  width: 80%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.footer-page-header {
  gap: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-title-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.footer-title {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 150%;
  font-family: Marvel;
  color: var(--general);
  text-transform: uppercase;
}

.footer-description {
  font-size: 30px;
  font-weight: 400;
  line-height: 38px;
  color: var(--secondary);
}

.footer-line {
  height: 2px;
  width: 100%;
  padding-top: 100px;
  border-bottom: 2px solid var(--general);
}

.footer-page-underline-section {
  width: 100%;
  display: flex;
  column-gap: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;
}

.footer-left-items,
.footer-right-items {
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
}

.footer-left-items {
  justify-content: flex-start;
}

.footer-right-items {
  justify-content: flex-end;
}

.footer-content-btns-config {
  display: flex;
  padding: 16px 56px;
  position: relative;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .underline-txt.link-item.nowrap {
    text-align: right;
    white-space: pre-wrap !important;
  }

  .footer-page-header {
    gap: 100px;
  }

  .footer-page-container {
    padding-bottom: 30px;
  }
}

@media (min-width: 600px) and (max-width: 940px) {
  .underline-txt.link-item.nowrap {
    white-space: pre-wrap !important;
  }

  .footer-left-items .underline-txt.link-item {
    text-align: left;
  }

  .footer-right-items .underline-txt.link-item {
    text-align: right;
  }
}

@media (max-width: 650px) {
  .underline-txt.link-item.nowrap {
    text-align: left;
  }

  .footer-page-underline-section {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .footer-page-underline-section {
    column-gap: 0;
    flex-direction: column;
  }

  .footer-left-items,
  .footer-right-items {
    gap: 10px;
    justify-content: flex-start;
  }
}

@media (min-width: 940px) and (max-width: 1300px) {
  .footer-page-underline-section {
    flex-wrap: wrap;
  }

  .footer-right-items {
    justify-content: flex-start;
  }
}
