.header-logo {
  height: 60px;
  width: 180px;
}

.header-links-container {
  width: 70%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-links-list {
  display: flex;
}

.header-link-item-list:not(:first-child) {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 46px;
  letter-spacing: 0em;
}

.header-link-item {
  color: var(--general);
}

.header-parent {
  width: 100%;
  z-index: 200;
  display: flex;
  position: relative;
  justify-content: center;
}

@media (max-width: 1300px) {
  .header-links-list {
    display: none;
  }
}
