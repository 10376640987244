@import url("https://fonts.googleapis.com/css?family=Space+Grotesk");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Space Grotesk;
}

a {
  text-decoration: none !important;
}

a:active {
  color: #8f9cff !important;
}

li {
  list-style: none !important;
}

button {
  outline: none !important;
  background: none !important;
}

.nodrag-noselect {
  user-select: none;
  -webkit-user-drag: none;
}

.link-item {
  color: var(--general) !important;
}

.nowrap {
  white-space: nowrap !important;
}

@media (min-width: 1100px) and (max-width: 1300px) {
  span,
  button,
  a {
    font-size: 26px !important;
  }
}

@media (max-width: 1100px) {
  span,
  button,
  a {
    font-size: 24px !important;
  }
}

@media (max-width: 450px) {
  span,
  button,
  a {
    font-size: 20px !important;
  }
}

:root {
  --general: white;
  --secondary: #00f0ff;
}

.writing-animation {
  width: 100%;
  overflow: hidden;
  position: relative;
  animation: typewriter 2s steps(44) 0s 1 normal both,
    blinkTextCursor 500ms steps(44) infinite normal;
}

@keyframes typewriter {
  from {
    width: 0;
    white-space: nowrap;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
  }
  to {
    width: 100%;
    white-space: unset;
    border-right: none;
  }
}

@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(255, 255, 255, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
