.humanity-content-page {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}

.humanity-content-container {
  width: 80%;
  display: flex;
  padding-top: 50px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.humanity-content-child {
  width: 100%;
  display: flex;
  text-align: left;
  flex-direction: column;
}

.humanity-content {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}

.content-text-configuration {
  line-height: 150%;
  letter-spacing: 0em;
}

.opening-content {
  max-width: 75%;
  font-size: 44px;
  font-weight: 400;
  color: var(--general);
  text-shadow: 0px 0px 10px #000000, 0px 4px 4px #000000;
}

.secondary-content {
  color: var(--secondary);
  text-shadow: 0px 0px 10px #000000, 0px 4px 4px #000000;
}

@media (min-width: 1100px) {
  .secondary-content {
    font-size: 50px;
    font-weight: 700;
    animation: growth 0.5s;
    animation-fill-mode: both;
    animation-iteration-count: 2;
  }
}

@keyframes growth {
  0% {
    font-size: 50px;
  }
  5% {
    font-size: 50.5px;
  }
  15% {
    font-size: 51px;
  }
  25% {
    font-size: 51.5px;
  }
  35% {
    font-size: 52px;
  }
  45% {
    font-size: 52.5px;
  }
  50% {
    font-size: 53px;
  }
  55% {
    font-size: 52.5px;
  }
  65% {
    font-size: 52px;
  }
  75% {
    font-size: 51.5px;
  }
  85% {
    font-size: 51px;
  }
  95% {
    font-size: 50.5px;
  }
  100% {
    font-size: 50px;
  }
}

.contact-us-btn {
  color: var(--secondary);
  border: 2px solid var(--secondary);
}

.contact-us-btn:hover {
  background: rgba(0, 240, 255, 0.15) !important;
}

.learn-more-btn:hover {
  background: rgba(217, 217, 217, 0.15) !important;
}

.first-content-btns {
  z-index: 100;
  width: 590px;
  display: flex;
  margin-top: 50px;
  position: relative;
  justify-content: space-between;
}

.learn-more-btn {
  color: var(--general);
  border: 2px solid var(--general);
}

.first-content-btns-config:hover {
  cursor: pointer;
}

.first-content-btns-config {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 270px;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  border-radius: 20px;
}

@media (max-witdh: 1300px) {
  .humanity-content-page {
    margin-left: 150px !important;
  }
}

@media (max-width: 700px) {
  .first-content-btns {
    gap: 20px;
    width: 100%;
  }

  .first-content-btns-config {
    height: 40px;
    width: 160px;
  }
}
