@import url("https://fonts.googleapis.com/css?family=Marvel");

.problems-content-page {
  min-height: 100vh;
  padding: 100px 50px 0 50px;
}

.problems-content-title {
  margin: 0;
  padding: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 75px;
  font-family: Marvel;
  color: var(--general);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.problems-diagram {
  display: grid;
  position: relative;
  justify-content: center;
  grid-template-rows: 90px 0 500px;
  grid-template-columns: repeat(3, minmax(auto, 500px));
}

.diagram-obj-config {
  font-size: 30px;
  color: var(--general);
}

.diagram-first-obj {
  grid-area: 2 / 1;
  text-align: right;
}

.diagram-second-obj {
  grid-area: 3 / 3;
  text-align: left;
  align-self: center;
}

.diagram-image {
  grid-area: 2 / 2;
  padding-top: 15px;
}

.diagram-image img {
  width: 442px;
  height: 468px;
}

.diagram-conclusion {
  font-size: 40px;
  font-weight: 500;
  line-height: 51px;
  padding-top: 100px;
  letter-spacing: 0em;
  color: rgba(18, 192, 204, 1);
}

@media (max-width: 1300px) {
  .diagram-img-child {
    display: none;
  }

  .problems-content {
    display: flex;
    padding-top: 100px;
    flex-direction: column;
  }

  .problems-diagram {
    justify-content: center;
    grid-template-columns: none;
    grid-template-rows: minmax(100px, 130px);
  }

  .diagram-first-obj {
    grid-area: 1 / 1;
    text-align: center;
  }

  .diagram-second-obj {
    grid-area: 2 / 1;
    text-align: center;
  }
}

@media (max-width: 800px) {
  .problems-content-page {
    min-height: 70vh;
  }
}
