@import url("https://fonts.googleapis.com/css?family=Marvel");

.solution-content-page {
  background: radial-gradient(
    322.66% 100% at 50% 0%,
    #0b0e13 25.59%,
    #212733 100%
  );
  min-height: 100vh;
  padding: 100px 50px 0 50px;
}

.solution-content-title {
  margin: 0;
  width: 785px;
  font-size: 50px;
  color: #ffffff;
  font-weight: 700;
  line-height: 150%;
  font-style: normal;
  font-family: "Marvel";
  text-transform: uppercase;
}

.solution-content-title-container {
  display: flex;
  justify-content: center;
}

.solution-options-container {
  width: 574px;
  height: 404px;
  display: grid;
  margin-top: 100px;
  grid-template-rows: repeat(3, 134px);
  grid-template-columns: repeat(3, 191px);
}

.option-text-0 {
  text-align: end;
  grid-area: 1 / 1;
  justify-self: flex-start;
}

.option-text-1 {
  grid-area: 2 / 2;
  justify-self: flex-start;
}

.option-text-2 {
  text-align: end;
  grid-area: 3 / 3;
  justify-self: flex-start;
}

.solution-option-text {
  font-size: 30px;
  font-weight: 500;
  text-align: right;
  line-height: 38px;
  white-space: nowrap;
  color: var(--general);
}

.solution-content-parent {
  display: flex;
  margin-left: 308px;
  margin-right: 320px;
  justify-content: space-between;
}

.writing-animation-0 {
  animation-delay: 0 !important;
}

.writing-animation-1 {
  animation-delay: 2s !important;
}

.writing-animation-2 {
  animation-delay: 4s !important;
}

@media (max-width: 1300px) {
  .solution-options-container {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    flex-direction: column;
    justify-content: space-around;
  }

  .solution-options-container div {
    display: flex;
    width: fit-content;
    align-items: center;
    flex-direction: column;
  }
}

@media (min-width: 770px) {
  .solution-options-container {
    margin-top: 100px;
  }
}

@media (max-width: 800px) {
  .solution-content-page {
    min-height: 70vh;
  }
}

@media (max-width: 770px) {
  .solution-options-container {
    margin-top: 100px;
    width: -webkit-fill-available;
  }

  .solution-options-container div,
  .solution-options-container div img {
    width: -webkit-fill-available;
  }
}
/* .man-body { */
/* animation: rotateY-anim 4s linear infinite; */
/* } */

/* @keyframes rotateY-anim {
  0% {
    transform: rotate3d(0, 0.5, -1, 0deg);
  }

  50% {
    transform: rotate3d(0, 1, 0, 180deg);
  }

  100% {
    transform: rotate3d(0, 2, 1, 360deg);
  }
} */
