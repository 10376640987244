@import url("https://fonts.googleapis.com/css?family=Marvel");

.public-health-page {
  position: relative;
  background: radial-gradient(
    322.66% 100% at 50% 0%,
    #212733 29.17%,
    #0b0e13 100%
  );
  overflow: hidden;
  min-height: 100vh;
  padding-bottom: 100px;
}

.public-health-title {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  line-height: 150%;
  padding-top: 100px;
  font-family: Marvel;
  color: var(--general);
  text-transform: uppercase;
}

.public-health-description {
  width: 250px;
  font-size: 30px;
  font-weight: 400;
  padding-top: 20px;
  line-height: 150%;
  color: var(--general);
  white-space: pre-line;
}

.croped-rect-container {
  width: 419px;
  height: 129px;
  display: flex;
  position: relative;
  border-radius: 10px;
}

.cropped-rect-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  color: var(--general);
}

.croped-rect-container-odd {
  text-align: left;
  justify-content: flex-start;
  background: url("../../../static/backgrounds/Rectangle\ 5861.png");
}

.croped-rect-container-even {
  text-align: right;
  margin-top: 90px;
  margin-left: 200px;
  justify-content: flex-end;
  background: url("../../../static/backgrounds/Rectangle\ 5862.png");
}

.cropped-rect-content-container {
  width: 68%;
  box-sizing: content-box;
}

.cropped-rect-content-container-odd {
  padding: 10px 0 15px 30px;
}

.cropped-rect-content-container-even {
  padding: 10px 30px 15px 0;
}

.rect-container-0 {
  grid-area: 0 / 0;
}

.rect-container-1 {
  grid-area: 0 / 1;
}

.rect-container-2 {
  grid-area: 3 / 3;
}

.rect-container-3 {
  grid-area: 3 / 4;
}

.public-health-content-container {
  display: flex;
}

.public-health-content {
  z-index: 100;
  display: grid;
  padding-top: 110px;
  padding-left: 200px;
  grid-template-rows: 220px 100px 180px auto;
  grid-template-columns: 120px 100px 120px 419px;
}

.earth-img {
  top: 0;
  right: 0;
  position: absolute;
  mix-blend-mode: screen;
}

.footer-container {
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  padding-left: 322px;
  padding-right: 322px;
}

.footer-link-item-list:not(:first-child) {
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
}

.footer-link-item {
  text-align: left;
  white-space: nowrap;
  color: var(--general);
}

.footer-line {
  margin: 0;
  width: 100%;
  border-bottom: 0.85px solid #ffffff;
}

.footer-links-list {
  margin: 0;
  padding: 0;
  z-index: 100;
  display: grid;
  text-align: left;
  position: relative;
  align-items: center;
  grid-template-rows: 50px 50px;
  grid-template-columns: 150px 150px auto;
}

.footer-child {
  display: flex;
  align-items: center;
  padding-bottom: 59px;
  justify-content: space-between;
}

.footer-underline-section {
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;
}

.footer-info-section {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.footer-logo-parent {
  height: fit-content;
}

.mailto-link,
.phone-number,
.copyright-txt,
.terms-of-use-btn,
.privacy-policy-btn {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  font-family: Roboto;
  color: var(--general);
}

.footer-pages-section {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media (max-width: 1300px) {
  .public-health-content {
    width: 100%;
    height: 900px;
    display: flex;
    padding: 90px 0 0 0;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
  }

  .croped-rect-container-even {
    margin: 0;
  }
}

@media (max-width: 800px) {
  .public-health-page {
    min-height: 55vh;
  }
}

@media (max-width: 480px) {
  .cropped-rect-content-container {
    padding: 3px 15px 15px 15px;
  }

  .croped-rect-container {
    width: 300px;
  }
}
