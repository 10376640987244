.first-section {
  display: grid;
  position: relative;
  grid-template-rows: repeat(auto-fit, minmax(0, 0.3fr));
  background: radial-gradient(
    138.83% 100% at 50% 0%,
    #58697f 0%,
    #0b0e13 90.05%
  );
  min-height: 100vh;
}

.first-section-footer {
  display: flex;
  justify-content: center;
  font-size: 20px;
  background: #0b0e13;
  color: rgba(0, 240, 255, 0.8);
}

.second-section {
  min-height: 100vh;
  background: #0b0e13;
}

.diagram-background-img {
  top: 65%;
  right: 0;
  position: absolute;
}

.third-section {
  background: radial-gradient(
    322.66% 100% at 50% 0%,
    #0b0e13 25.59%,
    #212733 100%
  );
  min-height: 100vh;
}

.header-graphic-elements {
  top: 0;
  right: 0;
  position: absolute;
  mix-blend-mode: screen;
  background-blend-mode: screen;
}

.man-body .word {
  position: relative;
  transform-style: preserve-3d;
  transform: rotateX(-15deg) rotateY(0);
  height: 100%;
  width: 100%;
}

.man-body .char {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%)
    rotateY(calc(((360 / var(--char-total)) * var(--char-index)) * 1deg))
    translate(var(--font-size), 0);
}

.man-body .word[data-scroll="in"] {
  -webkit-animation: spin 2s 0.25s infinite linear;
  animation: spin 2s 0.25s infinite linear;
}

@-webkit-keyframes spin {
  to {
    transform: rotateX(-15deg) rotateY(-360deg);
  }
}

@keyframes spin {
  to {
    transform: rotateX(-15deg) rotateY(-360deg);
  }
}

.scroll-section {
  margin: 0;
  gap: 15px;
  display: flex;
  padding-top: 180px;
  align-items: flex-start;
  justify-content: center;
  background-color: #0b0e13;
}

.scroll-section img {
  height: 25px;
}

@media (max-width: 1300px) {
  .earth-img,
  .diagram-background-img {
    display: none;
  }

  .first-section {
    overflow: hidden;
  }

  .scroll-section {
    padding-top: 70px;
  }
}

@media (max-width: 800px) {
  .first-section {
    min-height: 80vh;
    grid-template-rows: repeat(auto-fit, minmax(0, 0.4fr));
  }

  .second-section {
    min-height: 55vh;
  }

  .third-section {
    min-height: 55vh;
  }
}

@media (max-width: 1800px) {
  .solution-content-parent {
    flex-direction: column;
  }

  .man-body {
    display: none;
  }
}

@media (max-width: 1200px) {
  .solution-content-parent {
    margin: 0;
    align-items: center;
  }

  .solution-options-container {
    margin-top: 100px;
  }
}
